import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { MantineProvider } from "@mantine/core";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql/client";
import { Notifications } from "@mantine/notifications";

type Props = {};

const router = createBrowserRouter(routes);

const App = (props: Props) => {
  return (
    <ApolloProvider client={client}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications position="top-center" />
        <RouterProvider router={router} />
      </MantineProvider>
    </ApolloProvider>
  );
};

export default App;
