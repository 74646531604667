import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/NavBar";

// type Props = {};

const StoreFront = () => {
  return (
    <Fragment>
      <NavBar />
      <div className="bg-white">
        <main>
          <Outlet />
        </main>
      </div>
    </Fragment>
  );
};

export default StoreFront;
