/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProductStatus {
  available = "available",
  out_of_stock = "out_of_stock",
  unlisted = "unlisted",
}

export enum ProductType {
  price = "price",
  quantity = "quantity",
}

export interface AuthInput {
  otp?: string | null;
  token?: string | null;
}

export interface CreateOrderInput {
  total: number;
  items?: (OrderProduct | null)[] | null;
  delivery: DeliveryInput;
  customerPhone: string;
  customerName: string;
  paymentRef: string;
}

export interface DeliveryInput {
  zone: string;
  estate: string;
  suite: string;
}

export interface IdOperator {
  eq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface OrderProduct {
  id: string;
  amount: number;
  quantity: number;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface ProductFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
}

export interface StringOperator {
  eq?: string | null;
  contains?: (string | null)[] | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
}

export interface UserInput {
  name: string;
  phone: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
