import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetProducts_getProducts } from "../graphql/queries/__generated__/GetProducts";

interface OrderItem extends GetProducts_getProducts {
  qty: number;
  amount: number;
}

export interface CartState {
  items: OrderItem[];
  orderTotal: number;
}

const initialState: CartState = {
  items: [],
  orderTotal: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, { payload }: PayloadAction<OrderItem>) => {
      state.items = [
        ...state.items.filter((el) => el.id !== payload.id),
        payload,
      ];
      state.orderTotal = state.items.reduce(
        (acc, curr) => acc + curr?.amount * curr?.qty,
        0
      );
    },
    removeItemFromCart: (
      state,
      { payload }: PayloadAction<number | string>
    ) => {
      state.items = state.items.filter((el) => el.id !== payload);
      state.orderTotal = state.items.reduce(
        (acc, curr) => acc + curr?.amount * curr?.qty,
        0
      );
    },
    clearCart: (state) => {
      state.items = [];
      state.orderTotal = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addItemToCart, removeItemFromCart, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
