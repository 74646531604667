import { useQuery } from "@apollo/client";
import { getProducts } from "../graphql/queries/products";
import {
  GetProducts,
  GetProductsVariables,
} from "../graphql/queries/__generated__/GetProducts";

export const useProducts = (variables?: GetProductsVariables) => {
  //hook logic
  const { data, loading, error, fetchMore } = useQuery<
    GetProducts,
    GetProductsVariables
  >(getProducts, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  return {
    fetchMore,
    products: data?.getProducts ?? [],
    length: data?.getProductsLength ?? 0,
    loading,
    error,
  };
};
