import React, { Fragment, useEffect } from "react";
import { ProductItem } from "../../components";
import { useProducts } from "../../hooks/useProducts";
import { superstate } from "@superstate/core"
import { useInView } from 'react-hook-inview'

type Props = {};

const LIMIT = 20;

const offset = superstate(0);

const HomePage = (props: Props) => {
  const { products, fetchMore, length } = useProducts({
    pagination: {
      limit: LIMIT,
      offset: 0,
    }
  });

  const [ref, isVisible] = useInView({
    threshold: 1,
  })

  const handleOnEndReached = () => {
    // console.log('Hello there', offset.now() + LIMIT);
    if (length > offset.now() + LIMIT) {
      return fetchMore({
        variables: {
          pagination: {
            offset: offset.now() + LIMIT,
            limit: LIMIT,
          },
          // filter: {
          //   random: true,
          // },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          offset.set(offset.now() + LIMIT);
          if (!fetchMoreResult) return prev;
          return {
            getProducts: prev.getProducts?.concat(fetchMoreResult.getProducts || []) || [],
            getProductsLength: fetchMoreResult.getProductsLength || 0,
          };
        },
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      handleOnEndReached();
    }
  }, [isVisible]);

  return (
    <Fragment>
      <section aria-labelledby="trending-heading">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 sm:py-32 lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <h2
              id="favorites-heading"
              className="text-2xl font-bold tracking-tight text-gray-900"
            >
              Trending Products
            </h2>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-x-2 gap-y-4 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
            {products?.map((product, index) => (
              <ProductItem product={product} key={index} />
            ))}
            {/* <!-- More products... --> */}
          </div>
          <div ref={ref}>
            {isVisible && <div>{length > offset.now() + LIMIT ? "Loading ..." : "End of list"}</div>}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default HomePage;
