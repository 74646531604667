import Lottie from "react-lottie";
import * as animationData from "../../assets/successful-check.json";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearCart } from "../../features/cart";
import { useNavigate } from "react-router-dom";


const CheckoutSuccess = () => {
  const dispatch = useAppDispatch();
  const { items, orderTotal } = useAppSelector((state) => state.cart);
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const serviceCharge =
    orderTotal > 300
      ? Math.ceil(orderTotal * 0.05)
      : Math.ceil(orderTotal * 0.1);
  const total = orderTotal + 5 + serviceCharge;

  const handleContinue = () => {
    dispatch(clearCart());
    navigate("/", { replace: true });
  };



  // useEffect(() => {
  //   const cleanup = () => {
  //     // Perform your cleanup or execute code here
  //     console.log('Route exited:', location.pathname);
  //   };

  //   // Add the cleanup function as a callback to be executed when the component unmounts
  //   return () => {
  //     console.log("Exited")
  //   };
  // }, []);

  return (
    <div className="flex flex-col items-center justify-center flex-1">
      <Lottie options={defaultOptions} height={200} width={400} />
      {/* <h2 className="font-bold text-xl">Order Placed Successfully</h2> */}
      <div className="md:max-w-xl px-4">
        <div className="container mx-auto py-8">
          <h2 className="text-2xl font-bold mb-4">
            Order Placed Successfully!
          </h2>

          <p className="mb-4">Hey there!!!!</p>

          <p className="mb-4">
            Thank you for choosing to shop with us! We are delighted to inform
            you that your order has been placed successfully. Here are the
            details of your purchase:
          </p>

          <h3 className="text-lg font-bold mb-2">Order Summary:</h3>
          <ul className="list-disc pl-6 mb-4">
            {items?.map((item, key) => (
              <li key={key}>
                {item?.name}: Quantity: {item?.qty}, Price: {item?.amount}
              </li>
            ))}
          </ul>
          <p className="mb-2">Subtotal: GHS {orderTotal}</p>
          <p className="mb-2">Shipping Fee: GHS {5}</p>
          <p className="mb-2">Service charge: GHS {serviceCharge}</p>
          <p className="mb-4 font-bold">Total: GHS {total}</p>

          <p className="mb-4">
            We will begin processing your order immediately, and you can expect
            a confirmation SMS with tracking information once your package is
            shipped. Should you have any questions or concerns regarding your
            order, please don't hesitate to contact our customer support team at{" "}
            <b>0556698408</b>.
          </p>

          <p className="mb-4">
            Thank you again for choosing our services. We look forward to
            serving you!
          </p>

          <button
            onClick={handleContinue}
            className="bg-teal-500 p-3 w-full shadow-sm rounded-md text-white hover:bg-teal-900 transform transition-all duration-300"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
