import { Modal } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import { GetProducts_getProducts } from "../../graphql/queries/__generated__/GetProducts";
import { useAppDispatch } from "../../app/hooks";
import { addItemToCart } from "../../features/cart";
import { ProductType } from "../../graphql/__generated__/globalTypes";
import Show from "../Show/Show";

type Props = {
  product: GetProducts_getProducts;
};

const ProductItem = ({ product }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [qty, setQty] = useState(1);
  const [amount, setAmount] = useState<number>(product?.price);
  const dispatch = useAppDispatch();

  const isQuantity = useMemo(
    () => product?.type === ProductType["quantity"],
    [product?.type]
  );

  const addItem = () => {
    setShowDetails(false);
    dispatch(
      addItemToCart({
        ...product,
        qty,
        amount,
      })
    );
  };

  return (
    <Fragment>
      <div onClick={() => setShowDetails(true)} className="group border p-1 rounded-md">
        <div className="h-24 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80">
          <img
            src={product?.image}
            alt="Hand stitched, orange leather long wallet."
            className="h-full w-full object-contain object-center bg-gray-200"
          />
        </div>
        <h3 className="mt-4 text-sm text-gray-700">
          <span>
            <span className=""></span>
            {product?.name}
          </span>
        </h3>
        {/* <p className="mt-1 text-sm text-gray-500">Natural</p> */}
        <p className="mt-1 text-sm font-medium text-gray-900">
          GHS {product?.price}
        </p>
        <button
          onClick={() => setShowDetails(true)}
          className="p-1 bg-teal-700 text-white px-11 mt-3 rounded-md w-full"
        >
          Get
        </button>
      </div>

      <Modal
        opened={showDetails}
        onClose={() => setShowDetails(false)}
        title={isQuantity ? "How many ?" : "How much ?"}
        // centered
        closeOnClickOutside={false}
      >
        <div className="h-56 w-full overflow-hidden rounded-md group-hover:opacity-75 lg:h-72 xl:h-80">
          <img
            src={product?.image}
            alt="Hand stitched, orange leather long wallet."
            className="h-full w-full object-contain object-center"
          />
        </div>
        <div className="bg-white py-4  z-auto">
          <h3 className=" text-sm text-gray-700">
            <h2>{product?.name}</h2>
          </h3>
          <p className="mt-1 text-sm font-medium text-gray-900">
            GHS {product?.price}
          </p>
          <Show if={!isQuantity}>
            <input
              min={product?.price}
              value={amount}
              onChange={(e) => setAmount(e.target.valueAsNumber)}
              type="number"
              placeholder={`how much, starts from  GHS ${product?.price}`}
              className="my-3 p-3 border w-full"
            />
          </Show>
          <Show if={isQuantity}>
            <div className="grid grid-cols-3 gap-3 p-2 ">
              <div className="flex items-center justify-center">
                <button
                  onClick={() => setQty((prev) => (prev < 2 ? prev : prev - 1))}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-center justify-center">{qty}</div>
              <div className="flex items-center justify-center">
                <button onClick={() => setQty((prev) => prev + 1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>

            </div>
          </Show>
          <button
            onClick={addItem}
            className="p-1 bg-teal-700 text-white px-11 mt-3 rounded-sm w-full"
          >
            Add
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductItem;
