import React, { Fragment, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  if: boolean;
}>;

const Show = ({ if: cond, children }: Props) => {
  if (!cond) return null;
  return <Fragment>{children}</Fragment>;
};

export default Show;
