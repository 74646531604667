import { gql } from "@apollo/client";

export const userLogin = gql`
  mutation UserLogin($phone: String!) {
    userLogin(phone: $phone) {
      token
    }
  }
`;

export const authLastSrtep = gql`
  mutation AuthLastStep($input: AuthInput) {
    authLastStep(input: $input) {
      token
      user {
        id
      }
    }
  }
`;

export const createAccount = gql`
  mutation CreateUser($input: UserInput) {
    createUser(input: $input) {
      token
    }
  }
`;

export const createOrder = gql`
  mutation CreateOrder($input: CreateOrderInput) {
    createOrder(input: $input)
  }
`;
