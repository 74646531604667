import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Link, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  CreateOrder,
  CreateOrderVariables,
} from "../../graphql/mutations/__generated__/CreateOrder";
import { useMutation } from "@apollo/client";
import { createOrder } from "../../graphql/mutations";
import { LoadingOverlay } from "@mantine/core";
import { removeItemFromCart } from "../../features/cart";
import { notifications } from "@mantine/notifications";
import { PAYSTACK_KEY } from "../../constants";
import { Show } from "../../components";

type Props = {};

export interface CheckoutPayload {
  reference: string;
  trans: string;
  status: string;
  message: string;
  transaction: string;
  trxref: string;
  redirecturl: string;
}

const Checkout = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { items, orderTotal } = useAppSelector((state) => state.cart);
  const { register, handleSubmit, getValues, setValue } =
    useForm<CreateOrderVariables>({
      defaultValues: {
        input: {
          items: items?.map((el) => ({
            amount: el?.amount,
            id: el?.id,
            // product: el as any,
            quantity: el?.qty,
          })),
        },
      },
    });

  const [create, { loading }] = useMutation<CreateOrder, CreateOrderVariables>(
    createOrder
  );

  const serviceCharge =
    orderTotal > 300
      ? Math.ceil(orderTotal * 0.05)
      : Math.ceil(orderTotal * 0.1);
  const total = orderTotal + 5 + serviceCharge;

  const initializePayment = usePaystackPayment({
    reference: new Date().getTime().toString(),
    email: "groceryhub7@gmail.com",
    amount: total * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: PAYSTACK_KEY,
    channels: ["mobile_money"],
    currency: "GHS",
    label: getValues()?.input?.customerName,
    transaction_charge: 100
  });

  // you can call this function anything
  const onSuccess = (reference?: CheckoutPayload) => {
    setValue("input.paymentRef", reference?.reference ?? "");
    setValue("input.total", total);
    const data = getValues();
    // Implementation for whatever you want to do with reference and after success call.
    create({ variables: data })
      .then((res) => {
        navigate("/checkout-success", { replace: true });
      })
      .catch((err) => {
        notifications.show({
          message: err?.message,
          title: "Sorry Something went wrong",
          color: "red",
        });
      });
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const handleFormSubmit: SubmitHandler<CreateOrderVariables> = (data) => {
    initializePayment(onSuccess, onClose);
  };

  return (
    <Fragment>
      <LoadingOverlay visible={loading} />
      <div className="bg-white">
        {/* <!-- Background color split screen for large screens --> */}
        <div
          className="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block"
          aria-hidden="true"
        ></div>
        <div
          className="fixed right-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block"
          aria-hidden="true"
        ></div>

        <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
          <h1 className="sr-only">Order information</h1>

          <section
            aria-labelledby="summary-heading"
            className="bg-gray-50 px-4 pb-10 md:pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
          >
            <Link
              to="/"
              type="button"
              className="bg-gray-200 p-1 mx-auto max-w-lg lg:max-w-none rounded-md text-xs flex items-center justify-start space-x-2 mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
              <span>Go back</span>
            </Link>
            <div className="mx-auto max-w-lg lg:max-w-none">
              <h2
                id="summary-heading"
                className="text-lg font-medium text-gray-900"
              >
                Order summary
              </h2>

              <Show if={items?.length > 0}>
                <div className="bg-teal-200 text-teal-700 p-4 text-sm">
                  For orders 300 cedis and below, a 10% service charge will be
                  applied, making your purchases more affordable. For orders
                  exceeding 300 cedis, a 5% service charge will be provided,
                  ensuring greater value for larger purchases.
                </div>
              </Show>

              <ul
                // role="list"
                className="divide-y divide-gray-200 text-sm font-medium text-gray-900"
              >
                {items?.map((item, key) => (
                  <li key={key} className="flex items-start space-x-4 py-6">
                    <img
                      src={item?.image}
                      alt="Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps."
                      className="h-20 w-20 flex-none rounded-md object-cover object-center"
                    />
                    <div className="flex-auto space-y-1">
                      <h3>{item?.name}</h3>
                      <p className="text-gray-500">Qty: {item?.qty}</p>
                      <p className="text-gray-500">GHS {item?.amount}</p>
                      <button
                        onClick={() => dispatch(removeItemFromCart(item?.id))}
                        className="text-red-700 text-xs font-light"
                      >
                        remove
                      </button>
                    </div>
                    <p className="flex-none text-base font-medium">
                      GHS {item?.qty * item?.amount}
                    </p>
                  </li>
                ))}

                {/* <!-- More products... --> */}
              </ul>

              <dl className=" space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Subtotal</dt>
                  <dd>GHS {orderTotal}</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Shipping</dt>
                  <dd>GHS 5.00</dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Service charge</dt>
                  <dd>GHS {serviceCharge}</dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base"> GHS {total}</dd>
                </div>
              </dl>
            </div>
          </section>

          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16"
          >
            <div className="mx-auto max-w-lg lg:max-w-none">
              <section aria-labelledby="contact-info-heading">
                <h2
                  id="contact-info-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Contact information
                </h2>

                <div className="mt-6">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      autoComplete="name"
                      {...register("input.customerName", {
                        required: "Required",
                      })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone number
                  </label>
                  <div className="mt-1">
                    <input
                      type="tel"
                      autoComplete="phone"
                      {...register("input.customerPhone", {
                        required: "Required",
                      })}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                    />
                  </div>
                </div>
              </section>

              <section aria-labelledby="shipping-heading" className="mt-10">
                <h2
                  id="shipping-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Delivery address
                </h2>

                <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Campus
                    </label>
                    <div className="mt-1">
                      <select
                        id="company"
                        {...register("input.delivery.zone", {
                          required: "Required",
                        })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                      >
                        <option value="University Of Ghana">
                          University Of Ghana
                        </option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="UPSA">UPSA</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Hall
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="address"
                        {...register("input.delivery.estate", {
                          required: "Required",
                        })}
                        autoComplete="street-address"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="apartment"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Apartment or suite or Room Number
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="apartment"
                        {...register("input.delivery.suite", {
                          required: "Required",
                        })}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                <button
                  type="submit"
                  disabled={items?.length < 1}
                  className="w-full rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto"
                >
                  Continue
                </button>
                <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">
                  You won't be charged until the next step.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Checkout;
